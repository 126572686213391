import logo from "./logo.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          <h1>Bert and Bee</h1>
          <div>
            <h2>Fabulous Florals</h2>
            <p>Coming Soon,</p>
            <p>
              In the mean time, head over to our{" "}
              <a href="https://www.etsy.com/uk/shop/BertandBee">Etsy store</a>
            </p>
          </div>
        </div>
        <p className="App-copyright">
          <a href="mailto:info@bertandbee.com">info@bertandbee.com</a>
          <br />
          Copyright © 2023 Bert and Bee
        </p>
      </header>
    </div>
  );
}

export default App;
